import { createSlice } from '@reduxjs/toolkit';

import { addDrug } from '@actions/drug.actions';

const initialState = {
  error: null,
  status: 'idle',
  data: null,
};

const drugSlice = createSlice({
  name: 'drug',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [addDrug.pending]: (state) => {
      state.error = null;
      state.data = null;
      state.status = 'pending';
    },
    [addDrug.fulfilled]: (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.status = 'fulfilled';
    },
    [addDrug.rejected]: (state) => {
      state.error = null;
      state.data = null;
      state.status = 'rejected';
    },
  },
});

export default drugSlice.reducer;
