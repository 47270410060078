import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import DrugCamera from '@components/DrugCamera/DrugCamera.component';
import { addDrug } from '@actions/drug.actions';
import { fetchDrugs } from '@actions/drugs.actions';
import { getDrugsState } from '@selectors/drugs.selector';

const Drugs = () => {
  const { data: drugs } = useSelector(getDrugsState);
  const dispatch = useDispatch();

  useEffect(() => {
    !drugs && dispatch(fetchDrugs());
  }, [drugs, dispatch]);

  const handleAddDrug = async (drug) => {
    await dispatch(addDrug(drug));
    dispatch(fetchDrugs());
  };

  return (
    <Container fluid className="app-container p-0 m-0">
      <DrugCamera addDrug={handleAddDrug} />
    </Container>
  );
};

export default Drugs;
