import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '@slices/counterSlice';
import drugSlice from '@slices/drugSlice';
import drugsSlice from '@slices/drugsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    drug: drugSlice,
    drugs: drugsSlice,
  },
});
