import React, { useState, useEffect } from 'react';
import { Button, Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import Icon from '@ui/Icon';

export default function DrugJar({drugs: data, removeDrug, updateDrug}) {
  const [drugs, setDrugs] = useState([]);

  useEffect(() => data && setDrugs(data), [data]);

  const handleRemoveDrug = (drug) => removeDrug(drug)
  const handleDecreaseDrugQty = (drug) => updateDrug({...drug, qty: drug.qty - 1})

  return (
    !!drugs.length
      ? drugs.map((drug) => (
          <Card body className="mt-2 bg-light" key={drug._id}>
            <CardTitle tag="h5">{drug.name}</CardTitle>
            <CardText><span className="d-grid">Qty: {drug.qty}</span>
            <span className="d-grid">{drug.description}</span>
            <span className="d-grid">Expires: {drug.expire_date}</span></CardText>
            <Row className="d-md-flex justify-content-md-end">
              <Col xs={6}>
                <div className="d-grid">
                  <Button onClick={() => handleRemoveDrug(drug)} color="danger" alt="drug disposal"><Icon fa="faTimes"/></Button>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-grid">
                  <Button onClick={() => handleDecreaseDrugQty(drug)} color="success" alt="take a drug"><Icon fa="faPills"/></Button>
                </div>
              </Col>
            </Row>
          </Card>
        ))
      : <h2 className={'text-danger text-center p-5'}>There are no drugs unfortunelly</h2>
  )
}
