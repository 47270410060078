import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from '@views/Home';
import Drugs from '@views/Drugs';
import DrugsList from '@views/DrugsList';
import PrivateRoute from '@utils/privateRoute';
import { UserContext } from '@hooks/UserContext';
import useFindUser from '@hooks/useFindUser';

const Routes = () => {
  const { user, setUser, isLoading } = useFindUser();
  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <Switch>
          <Route exact path="/" component={Home} />
          <PrivateRoute exact path="/home" component={DrugsList} />
          <PrivateRoute exact path="/drugs" component={Drugs} />
        </Switch>
      </UserContext.Provider>
    </Router>
  );
};
export default Routes;
