import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from 'reactstrap';

export default function AddDrugModal({ item, addDrug, toggleModal }) {
  const [drug, setDrug] = useState({ name: '', qty: 0, expire_date: null });

  useEffect(() => item && setDrug({ ...item }), [item]);

  const handleAddDrug = () => addDrug(drug)
  const handleChange = e => {
    const value = {...drug};
    value[`${e.target.name}`] = e.target.value;
    setDrug({...value})
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader>Add Drug</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="textarea" value={drug.name} name="name" onChange={handleChange}></Input>
          </FormGroup>
          <FormGroup>
            <Label for="qty">Qty</Label>
            <Input type="text" value={drug.qty} name="qty" onChange={handleChange}></Input>
          </FormGroup>
          <FormGroup>
            <Label for="expire_date">Expire Date</Label>
            <Input type="text" value={drug.expire_date} name="expire_date" onChange={handleChange}></Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleAddDrug}>
          Add
        </Button>{' '}
        <Button color="secondary" onClick={toggleModal}>
          Reject
        </Button>
      </ModalFooter>
    </Modal>
  );
}
