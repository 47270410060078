import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const userAuth = createAsyncThunk('userAuth', async (credentials, thunkAPI) => {
  try {
    const response = await Axios.post(`${apiBaseUrl}/user`, credentials, {
      withCredentials: true,
      credentials: 'include',
    });
    if (!response.status === '200') {
      const error = await response.json();
      return thunkAPI.rejectWithValue({ error: error.message });
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.message ? error.message : error,
    });
  }
});

const userLogin = createAsyncThunk(
  'userLogin',
  async (credentials, thunkAPI) => {
    try {
      const response = await Axios.post(
        `${apiBaseUrl}/user/login`,
        credentials,
        {
          withCredentials: true,
          credentials: 'include',
        },
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

export { userAuth, userLogin };
