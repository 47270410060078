import React from 'react';
import Icon from '@ui/Icon'

export default function BrandBlock() {
  return(
    <div className="p-5">
      <p className="text-center text-danger">
        <Icon fa="faPlus" size="10x" color="red" />
      </p>
      <h1 className="text-center text-danger">Domowa apteczka</h1>
    </div>
  )
}