import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const fetchDrugs = createAsyncThunk('fetchDrugs', async (thunkAPI) => {
  try {
    const response = await Axios.get(`${apiBaseUrl}/drugs/list`, {
      withCredentials: true,
      credentials: 'include',
    });
    if (!response.status === '200') {
      const error = await response.json();
      return thunkAPI.rejectWithValue({ error: error.message });
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.message ? error.message : error,
    });
  }
});

export { fetchDrugs };
