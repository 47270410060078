import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import Icon from '@ui/Icon'

export default function Navigation() {
  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/home"><Icon fa="faPlus" size="lg" color="red"/><span className="text-danger p-3">Domowa apteczka</span></NavbarBrand>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink href="/drugs">Add Drug</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}
