import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import DrugJar from '@components/DrugJar/DrugJar.component';
import { fetchDrugs } from '@actions/drugs.actions';
import {removeDrug, updateDrug} from '@actions/drug.actions';
import { getDrugsState } from '@selectors/drugs.selector';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import Icon from '@ui/Icon'

const DrugsList = () => {
  const { data: drugs } = useSelector(getDrugsState);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    !drugs && dispatch(fetchDrugs());
  }, [drugs, dispatch]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleRemoveDrug = async (drug) => {
    await dispatch(removeDrug(drug));
    dispatch(fetchDrugs())
  };

  const handleUpdateDrug = async (drug) => {
    await dispatch(updateDrug(drug));
    dispatch(fetchDrugs())
  };

  return (
    <Container fluid className="app-container p-0 m-0">
      <Row>
        <Col xs={12}>
          <InputGroup>
            <Input value={search} type="text" placeholder="Find a drug" name="search" onChange={handleSearchChange}/>
            <InputGroupAddon addonType="append"><Button><Icon fa="faSearch"/></Button></InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DrugJar drugs={drugs} removeDrug={handleRemoveDrug} updateDrug={handleUpdateDrug}/>
        </Col>
      </Row>
    </Container>
  );
};

export default DrugsList;
