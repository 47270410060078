import React from 'react';
import Routes from '@routes';
import Navigation from '@components/navigation/Navigation.component';
import { Container } from 'reactstrap';
import '@styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <Container fluid>
      <Navigation />
      <div className="pt-5">
        <Routes />
      </div>
    </Container>
  );
}

export default App;
