import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { UserContext } from '@hooks/UserContext';
import UserLoginForm from '@components/UserLoginForm/UserLoginForm.component';
import BrandBlock from '@components/BrandBlock/BrandBlock.component'
import 'bootstrap/dist/css/bootstrap.min.css';
const Home = () => {
  const { user } = useContext(UserContext);

  if(user) {
     <Redirect to='/drugs'/>
  }

  return (
    <Container fluid className="app-container p-0 m-0">
      <Row>
        <Col xs={12}>
          <BrandBlock/>
        </Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={10}>
          <UserLoginForm />
        </Col>
        <Col xs={1}></Col>
      </Row>
    </Container>
  );
};

export default Home;
