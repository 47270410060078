import React, { useState } from 'react';
import Webcam from 'react-webcam';
import vision from 'react-cloud-vision-api';
import { Button } from 'reactstrap';
import AddDrugModal from '@components/AddDrugModal/AddDrugModal.component';
import Icon from '@ui/Icon';

const videoConstraints = {
  facingMode: 'environment',
  width:'100vw',
};

export default function DrugCamera({ addDrug }) {
  const [ imageSrc, setImageSrc ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ annotation, setAnnotation ] = useState(null);
  const [ isModalOpened, setIsModalOpened ] = useState(false);
  const webcamRef = React.useRef(null);
  const closeModal = () => setIsModalOpened(false);

  const capture = React.useCallback(() => {
    const img = webcamRef.current.getScreenshot();
    setError(null);

    vision.init({ auth: 'AIzaSyBvUQSqy7nmZwbcxWJfXNnvV3aiURgNgmw' });
    const req = new vision.Request({
      image: new vision.Image({
        base64: img,
      }),
      features: [
        // new vision.Feature('TEXT_DETECTION', 10),
        new vision.Feature('DOCUMENT_TEXT_DETECTION', 10),
        // new vision.Feature('LABEL_DETECTION', 10),
      ],
    });
    vision.annotate(req).then(
      (res) => {
        // handling response
        if (res.responses && res.responses[0].fullTextAnnotation) {
          setImageSrc(img);
          setAnnotation(res.responses[0].fullTextAnnotation.text);
          setIsModalOpened(true);
        } else {
          setError('Cannot resolve photo, please take another shot')
        }
      }
    );
  }, [webcamRef]);

  const restart = () => {
    closeModal();
    setImageSrc(null);
    setAnnotation(null);
  };

  const handleAddDrug = async (drug) => {
    await addDrug({ ...drug });
    restart();
  };

  return (
    <>
      <div className="text-center">
        {!imageSrc && (
          <div className="text-center">
            <Webcam
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              ref={webcamRef}
            />
          </div>
        )}
        {error && <span className="text-danger text-center p-2">{error}</span>}
        {(imageSrc && !error) && (
          <div className="text-center">
            <img src={imageSrc} alt="" />
          </div>
        )}
        <div className="d-grid p-2"><Button color="danger" size="lg" onClick={capture}><Icon fa={'faCamera'} size="4x"/></Button></div>
      </div>
      {isModalOpened && (
        <AddDrugModal
          item={{ name: annotation }}
          toggleModal={restart}
          addDrug={handleAddDrug}
        />
      )}
    </>
  );
}
