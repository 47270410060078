import { createSlice } from '@reduxjs/toolkit';

import { fetchDrugs } from '@actions/drugs.actions';

const initialState = {
  error: null,
  status: 'idle',
  data: null,
};

const drugsSlice = createSlice({
  name: 'drugs',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchDrugs.pending]: (state) => {
      state.error = null;
      state.data = null;
      state.status = 'pending';
    },
    [fetchDrugs.fulfilled]: (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.status = 'fulfilled';
    },
    [fetchDrugs.rejected]: (state, action) => {
      state.error = action.payload;
      state.data = null;
      state.status = 'rejected';
    },
  },
});

export default drugsSlice.reducer;
