import React, { useState } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userLogin } from '@actions/user.actions';


export default function UserLoginForm() {
  const [ user, setUser ] = useState({ username: '', password: '' });
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState(null)


  const dispatch = useDispatch();
  const history = useHistory();

  const handleUserLogin = () => {
    setIsLoading(true)
    dispatch(userLogin(user)).then(data => {
      if(data) { return history.push("/home")} 
      else {
        setIsLoading(false); 
        setError('Login error')
      }
    });
  };

  const handleChange = e => {
    const value = {...user};
    value[`${e.target.name}`] = e.target.value;
    setUser({...value})
  }

  return (
    <Form>
      <FormGroup>
        <Input type="name" name="username" onChange={handleChange} placeholder="User Name"></Input>
      </FormGroup>
      <FormGroup className="mt-2">
        <Input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
        ></Input>
      </FormGroup>
      <div className="d-grid mt-2">
        <Button fluid="true" color="danger" onClick={handleUserLogin} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Login'}
        </Button>
      </div>
      {error && <span>{error}</span>}
    </Form>
  );
}
