import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const addDrug = createAsyncThunk('addDrug', async (newDrug, thunkAPI) => {
  try {
    const response = await Axios.post(`${apiBaseUrl}/drugs`, newDrug, {
      withCredentials: true,
      credentials: 'include',
    });
    if (!response.status === '200') {
      const error = await response.json();
      return thunkAPI.rejectWithValue({ error: error.message });
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.message ? error.message : error,
    });
  }
});

const removeDrug = createAsyncThunk('removeDrug', async (drug, thunkAPI) => {
  try {
    const response = await Axios.delete(`${apiBaseUrl}/drugs/${drug._id}`, {
      withCredentials: true,
      credentials: 'include',
    });
    if (!response.status === '200') {
      const error = await response.json();
      return thunkAPI.rejectWithValue({ error: error.message });
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.message ? error.message : error,
    });
  }
});

const updateDrug = createAsyncThunk('decreaseDrug', async (drug, thunkAPI) => {
  try {
    const response = await Axios.patch(`${apiBaseUrl}/drugs/${drug._id}`, drug, {
      withCredentials: true,
      credentials: 'include',
    });
    if (!response.status === '200') {
      const error = await response.json();
      return thunkAPI.rejectWithValue({ error: error.message });
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.message ? error.message : error,
    });
  }
});

export { addDrug, removeDrug, updateDrug };
